import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ServicesLayout from "../../components/services-layout"
import BookButton from "../../components/book-button"

const StyledSection = styled.section`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: auto 1fr;
  margin-bottom: 2rem;
  @media (max-width: 400px) {
    & {
      grid-template-columns: auto;
      justify-items: center;
    }
  }
`

const StyledH2 = styled.h2`
  margin-top: 0;
`

export default () => {
  const data = useStaticQuery(graphql`
    fragment footSkinProblemsImages on File {
      childImageSharp {
        fixed(height: 135) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    query {
      corn: file(relativePath: { eq: "services/corn-treatment-perth.jpg" }) {
        ...footSkinProblemsImages
      }
      wart: file(relativePath: { eq: "services/wart-treatment-perth.jpg" }) {
        ...footSkinProblemsImages
      }
      tinea: file(
        relativePath: { eq: "services/tinea-athletes-foot-treatment-perth.jpg" }
      ) {
        ...footSkinProblemsImages
      }
      callus: file(
        relativePath: { eq: "services/callus-treatment-perth.jpg" }
      ) {
        ...footSkinProblemsImages
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Ingrown toenail treatment" />
      <ServicesLayout title="Common skin problems">
        <StyledSection>
          <Img
            fixed={data.callus.childImageSharp.fixed}
            alt="Callus and dry skin treatment Perth"
          />
          <div>
            <StyledH2>Calluses</StyledH2>
            <p>
              Calluses are caused by an accumulation of dead skin cells that
              harden and thicken over an area of the foot. It is the body's
              defence mechanism for protection against excessive pressure and
              friction.
            </p>
            <p>
              However, when the callus becomes too thick, it can cause pain and
              crack. Your podiatrist can easily reduce these calluses for you.
            </p>
          </div>
        </StyledSection>
        <StyledSection>
          <Img
            fixed={data.wart.childImageSharp.fixed}
            alt="Wart treatment Perth"
          />
          <div>
            <StyledH2>Warts</StyledH2>
            <p>
              Warts (also called Verrucae) are a painful growth of skin infected
              by the human papillomavirus. They cause pain on direct pressure
              and if you squeeze from the sides.
            </p>
            <p>
              Getting rid of a wart involves removing the top layer of hard skin
              and applying a caustic agent to kill the virus. Your podiatrist is
              trained in these techniques.
            </p>
          </div>
        </StyledSection>
        <StyledSection>
          <Img
            fixed={data.tinea.childImageSharp.fixed}
            alt="Tinea (athlete's foot) treatment Perth"
          />
          <div>
            <StyledH2>Tinea (Athlete's Foot)</StyledH2>
            <p>
              Tinea (more commonly known as 'athlete's foot') is the fungal
              infection of the skin causing itching and peeling under the foot
              and/or between the toes. You can contract tinea from public pools,
              showers, overseas travel, or contact with someone who has the
              infection.
            </p>
            <p>
              To treat tinea you should see your podiatrist for assessment so
              you are given the right treatment plan.
            </p>
          </div>
        </StyledSection>
        <StyledSection>
          <Img
            fixed={data.corn.childImageSharp.fixed}
            alt="Corn treatment Perth"
          />
          <div>
            <StyledH2>Corns</StyledH2>
            <p>
              Corns, like calluses, develop from an accumulation of dead skin
              cells on the foot, forming think, hardened spots. They contain a
              cone-shaped core with a point that can press on a nerve, causing
              pain.
            </p>
            <p>
              Corns are a very common ailment and feel like a pebble in your
              shoe. The podiatrist can remove your corn quite painlessly.
            </p>
          </div>
        </StyledSection>
        <BookButton text="Book an appointment" size="1.5rem" />
      </ServicesLayout>
    </Layout>
  )
}
